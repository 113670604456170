import React, { useEffect } from "react";
import { BuilderComponent } from "@builder.io/react";
import { graphql, HeadProps, PageProps } from "gatsby";
import MainLayout from "@layouts/main";
import SEOMeta from "@components/SEOMeta";
import { handleContentLoaded } from "@utils/builderContentLoaded";
import { RegisterComponentResourcePost } from "@utils/RegisterComponentResourcePost";
import { StringParam, useQueryParam } from "use-query-params";
import { Cookie } from "../../utils/Cookie";
import "@builder.io/widgets";

import { enableSmoothScroll } from "@utils/enableSmoothScroll";

type DataProps = {
  allBuilderModels: Queries.builder;
};

const ResourcePostTemplate: React.FC<PageProps<DataProps>> = ({
  data,
  location,
}) => {
  const content = data?.allBuilderModels.onePageResourcePost?.content;
  const isScrollable = content?.data?.useScroll;
  enableSmoothScroll(isScrollable);
  const [gateParam] = useQueryParam("gate", StringParam);

  useEffect(() => {
    if (gateParam === "bypass") {
      Cookie.setCookie("sp_resource", "true", 30, false);
    }
  }, [gateParam]);

  return (
    <MainLayout pathname={location.pathname}>
      {content && (
        <BuilderComponent
          content={content}
          model="page-resource-post"
          contentLoaded={handleContentLoaded}
        />
      )}
    </MainLayout>
  );
};

export default ResourcePostTemplate;

RegisterComponentResourcePost();

export const Head: React.FC<HeadProps<DataProps>> = ({ data }) => {
  const {
    allBuilderModels: { onePageResourcePost },
  } = data;

  const seoData = onePageResourcePost?.content
    ?.data as Queries.builder_PageFullNavData;

  return (
    <>
      <SEOMeta
        index={seoData.index!}
        title={seoData.title!}
        description={seoData.description!}
      />

      {seoData?.json && (
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: JSON.stringify({
              ...seoData.json,
            }),
          }}
        />
      )}
    </>
  );
};

export const query = graphql`
  query ($path: String!) {
    allBuilderModels {
      onePageResourcePost(
        target: { urlPath: $path }
        options: { cachebust: false, includeRefs: true, noTraverse: false }
      ) {
        content
      }
    }
  }
`;
