import { RegisterPricingPageToolTips } from "@components/BuilderComponents/PricingPageToolTips";
import { RegisterFaqsContainer } from "@components/BuilderWrapperComponents/FaqsAccordionBC";
import { RegisterLinkRedirectSignin } from "@components/BuilderWrapperComponents/LinkRedirectSigninWrapper";
import { RegisterSignUpForm } from "@components/BuilderWrapperComponents/SignUpFormBC";
import { RegisterSignupFormDrip } from "@components/BuilderWrapperComponents/SignupFormDripWrapper";
import { RegisterCtaNew } from "../components/RedesignComponents/BuilderWrappers/Cta/Cta";
import {
  RegisterButtonVideo,
  RegisterVideoEmbedWrapper,
} from "../components/RedesignComponents/BuilderWrappers/VideoEmbedWrapper/VideoEmbedWrapper";
import { RegisterFAQsAccordion } from "@components/RedesignComponents/BuilderWrappers/FAQsAccordionWrapper/FAQsAccordionWrapper";
import { RegisterSectionWithMargin } from "../components/RedesignComponents/SectionWithMargin/SectionWithMargin";
import { RegisterSignUpFormWrapper } from "../components/RedesignComponents/SignUpFormWrapper/SignUpFormWrapper";
import { RegisterLottie } from "../components/RedesignComponents/BuilderWrappers/LottieWrapper/LottieWrapper";
import { RegisterFeatureCard } from "../components/RedesignComponents/BuilderWrappers/FeatureCardWrapper/FeatureCardWrapper";
import { RegisterButterFlyEffect } from "../components/RedesignComponents/BuilderWrappers/SocialProofWrapper";
import { RegisterFeatureDetail } from "../components/RedesignComponents/BuilderWrappers/FeatureModuleDetailWrapper";
import { RegisterVerticalTab } from "../components/RedesignComponents/BuilderWrappers/TextCarouselWrapper/TextCarouselWrapper";
import { RegisterTooltip } from "@components/RedesignComponents/BuilderWrappers/TooltipWrapper/TooltipWrapper";
import { RegisterIllustrationCard } from "@components/RedesignComponents/BuilderWrappers/IllustrationCard/IllustrationCard";
import { RegisterPageHeroAnchorLinkWrapper } from "@components/RedesignComponents/BuilderWrappers/PageHeroAnchorLinkWrapper/PageHeroAnchorLinkWrapper";
import { RegisterCompareMatrixPricingWrapper } from "@components/RedesignComponents/BuilderWrappers/CompareMatrixPricingWrapper/CompareMatrixPricingWrapper";
import { RegisterImageWrapper } from "../components/RedesignComponents/BuilderWrappers/ImageWrapper/ImageWrapper";
import { RegisterFooter } from "@components/RedesignComponents/BuilderWrappers/FooterWrapper/FooterWrapper";

export const RegisterComponentSignForm = () => {
  RegisterSignUpForm();
  RegisterFaqsContainer();
  RegisterPricingPageToolTips();
  RegisterLinkRedirectSignin();
  RegisterSignupFormDrip();
  RegisterCtaNew();
  RegisterVideoEmbedWrapper();
  RegisterVideoEmbedWrapper();
  RegisterLottie();
  RegisterFeatureCard();
  RegisterButtonVideo();
  RegisterButterFlyEffect();
  RegisterFeatureDetail();
  RegisterVerticalTab();
  RegisterSectionWithMargin();
  RegisterFAQsAccordion();
  RegisterSignUpFormWrapper();
  RegisterTooltip();
  RegisterIllustrationCard();
  RegisterPageHeroAnchorLinkWrapper();
  RegisterCompareMatrixPricingWrapper();
  RegisterImageWrapper();
  RegisterFooter();
};
