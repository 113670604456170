/* eslint-disable no-console */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { SEOContext } from "gatsby-plugin-wpgraphql-seo";
import GlobalQueryParams from "../GlobalQueryParams";
import SchemaScript from "../SchemaScript";
import LayoutSEOQuery from "../../assets/StaticQueries/LayoutSEO.query";
import ModalContainer from "../../containers/ModalContainer";
import PageScripts, { SEGMENT_KEY } from "../PageScripts";
import builder from "@builder.io/react";
import { StringParam, useQueryParam } from "use-query-params";
import { externalRedirect } from "@utils/externalRedirect";
import { Cookie } from "@utils/Cookie";
import CookieConsent from "@components/RedesignComponents/CookieConsent/CookieConsent";
import {
  segmentTrack,
  sendingPendingSegmentEvents,
} from "@utils/segment/segment-track";

builder.init(process.env.GATSBY_BUILDER_API_KEY!);

type DataProps = {
  props: any;
  element: any;
};

type ExternalDataType = {
  sourceUrl: string;
  targetUrl: string;
};

type DataGrailConsentEventType = {
  consentPreferences: {
    cookieOptions: { gtm_key: string; isEnabled: boolean }[];
  };
};

const PageElementWrapper: React.FC<DataProps> = ({ element, props }) => {
  const seo = LayoutSEOQuery();
  const [p] = useQueryParam("p", StringParam);

  useEffect(() => {
    (async function () {
      const redirectList = await externalRedirect();

      if (redirectList && redirectList.length > 0) {
        redirectList.forEach(item => {
          const redirect = item?.data?.redirect;

          const currentUrl = redirect?.find(
            (item: ExternalDataType) =>
              item?.sourceUrl === window.location.pathname
          );

          if (currentUrl) {
            const newUrl = p
              ? `${currentUrl?.targetUrl}${p}`
              : currentUrl?.targetUrl;

            return (window.location.href = newUrl);
          }
        });
      }
    })();
  }, []);

  const rebrandPage =
    props?.data?.allBuilderModels?.onePageFullNav?.content?.data?.rebrandPage;

  useEffect(() => {
    rebrandPage
      ? document.body.classList.add("sp-redesign")
      : document.body.classList.remove("sp-redesign");
  }, [rebrandPage]);

  useEffect(() => {
    if (p) {
      Cookie.setCookie("PROMOCODE", p, 365);
    }
    if (
      Boolean(
        JSON.parse(localStorage.getItem("visitedPricingPage") as string)?.value
      )
    ) {
      const expireDate = JSON.parse(
        localStorage.getItem("visitedPricingPage") as string
      ).expiresOn;
      if (Date.now() > expireDate) {
        localStorage.removeItem("visitedPricingPage");
      }
    }
  }, []);

  const options = {
    schemaReplacement: {
      from: "https://spgatsby.wpengine.com",
      to: "https://www.simplepractice.com",
    },
  };

  const preferencesCallback = (event: DataGrailConsentEventType) => {
    const data = {
      cookie_preferences: event?.consentPreferences?.cookieOptions,
      initiator: "User",
      object: "Privacy Choices",
      action: "Updated",
      url: window.location.pathname,
      source_application: "Marketing",
    };

    segmentTrack("Privacy Choices Updated", data);
  };

  useEffect(() => {
    window.dgEvent = window.dgEvent || [];
    window.dgEvent.push({
      event: "preference_callback",
      params: preferencesCallback,
    });

    document.addEventListener("dg:scriptinjected", (event: Event) => {
      const script = event?.detail?.script;

      if (!script) return;

      const consentId = script.getAttribute("data-consent-id");

      if (consentId === SEGMENT_KEY) {
        sendingPendingSegmentEvents();
      }
    });
  }, []);

  return (
    <SEOContext.Provider value={{ global: seo, options }}>
      <ModalContainer {...props}>
        <PageScripts />
        <SchemaScript
          jsonScript={props.pageContext?.jsonLdSchema?.schemaScript}
        />
        <GlobalQueryParams />
        {element}
      </ModalContainer>
      <CookieConsent />
    </SEOContext.Provider>
  );
};

export default PageElementWrapper;
